// Menu Toggle
// var menuToggle = function (event) {

//  if (!event.target.matches('.mobileMenuToggle')) return;
//  document.querySelector('body').classList.toggle('navOpen');
//  if(!event.target.matches('.mobileContact')){
//   event.preventDefault();
//  }

// };

// document.addEventListener('click', function (event) {
//   menuToggle(event);
// });

// Form processing with recaptcha and messaging
var contactForm = document.getElementById('contactForm');

if(contactForm){
  contactForm.addEventListener('submit', function (event) {
    event.preventDefault();
    document.querySelector('.submitButton').disabled = true;
    grecaptcha.reset();
    grecaptcha.execute();
  });
}

function formSubmit(response) {
  document.getElementById('contactForm').submit();
}
// End: Form processing with recaptcha and messaging

// --- TOASTER --- //

// Look for a message parameter
function getParameterByName(name, url) {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
var message = getParameterByName("message");

if(message === 'failed'){
  MessageBanner('Something has gone wrong.', 'Please call Cathie on 07989 572 712', 'error');
}
if(message === 'success'){
  MessageBanner('Message sent.', 'Thank you. I\'ll be in touch soon', 'go');
}

// // Create our message timer
// var messagetimer;

// function messageToaster(title, message, type) {

//   clearTimeout(messagetimer);

//   var toaster = document.querySelector('.toaster');

//   clearToaster(toaster);

//   if(type === 'failed'){
//     toaster.classList.add('failed');
//   } else {
//     toaster.classList.add('success');
//   }

//   document.querySelector('.toaster__content').innerText = message;
//   toaster.classList.add('open');

//   messagetimer = setTimeout(function() {
//       toaster.classList.remove('open');
//       window.history.replaceState(null, null, window.location.pathname);
//   }, 8000);

// }

// function clearToaster(toaster) {
//   toaster.classList.remove('failed', 'success');
// }

// // First at the URL to target correct message
// function getParameterByName(name, url) {
//     if (!url) {
//         url = window.location.href;
//     }
//     name = name.replace(/[\[\]]/g, "\\$&");
//     var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
//         results = regex.exec(url);
//     if (!results) return null;
//     if (!results[2]) return '';
//     return decodeURIComponent(results[2].replace(/\+/g, " "));
// }

// --- TOASTER END --- //

// Reframe
!function(e,t){"object"==typeof exports&&"undefined"!=typeof module?module.exports=t():"function"==typeof define&&define.amd?define(t):(e="undefined"!=typeof globalThis?globalThis:e||self).reframe=t()}(this,function(){"use strict";function t(){for(var e=0,t=0,n=arguments.length;t<n;t++)e+=arguments[t].length;for(var i=Array(e),o=0,t=0;t<n;t++)for(var r=arguments[t],f=0,d=r.length;f<d;f++,o++)i[o]=r[f];return i}return function(e,s){return void 0===s&&(s="js-reframe"),("string"==typeof e?t(document.querySelectorAll(e)):"length"in e?t(e):[e]).forEach(function(e){var t,n,i,o,r,f,d,l;-1!==e.className.split(" ").indexOf(s)||-1<e.style.width.indexOf("%")||(i=e.getAttribute("height")||e.offsetHeight,o=e.getAttribute("width")||e.offsetWidth,r=("string"==typeof i?parseInt(i):i)/("string"==typeof o?parseInt(o):o)*100,(f=document.createElement("div")).className=s,(d=f.style).position="relative",d.width="100%",d.paddingTop=r+"%",(l=e.style).position="absolute",l.width="100%",l.height="100%",l.left="0",l.top="0",null!==(t=e.parentNode)&&void 0!==t&&t.insertBefore(f,e),null!==(n=e.parentNode)&&void 0!==n&&n.removeChild(e),f.appendChild(e))})}});
reframe('.video');

var messagetimer;

function MessageBanner(title, message, type) {

  clearTimeout(messagetimer);
  $('#message-box').hide();
  $('#message-box').removeClass();

  if (type == 'error') {
    $('#message-box').addClass("no");
  } else {
    $('#message-box').addClass("thanks");
  }

  $('#message-box .title').html(title);
  $('#message-box .content').html(message);
  $('#message-box').slideDown(300);

  messagetimer = setTimeout(function() {

    $('#message-box').fadeOut(500);
    window.history.replaceState(null, null, window.location.pathname);

  }, 3000);

}

// Old JS
var today = getTodayDate();

handleAnnouncements(today);

function handleAnnouncements(date){
  if(localStorage.getItem("ckAnnouncement")){

    var storedDate = localStorage.getItem("ckAnnouncement");
    if(storedDate < date){
      $('.announcement').show();
      localStorage.removeItem("ckAnnouncement");
    } else {
      $('.announcement').remove();
    }

  } else {
    $('.announcement').show();
  }
}

function getTodayDate(){
  var date = new Date();
  var dd = String(date.getDate()).padStart(2, '0');
  var mm = String(date.getMonth() + 1).padStart(2, '0');
  var yyyy = date.getFullYear();
  var today = yyyy+mm+dd;
  return today;
}

$(function() {

  // Some date-based banner magic //
  var now = new Date();
  var bannerExpires = new Date("October 17, 2016"); // Day after event

  if(now < bannerExpires) // today is after Christmas
  {
       $('.message-banner').addClass('active');
       $('.main-copy').addClass('message-stripe');
  }

  $('.announcement__close, .announcement a').click(function(){
      $('.announcement').remove();
      localStorage.setItem("ckAnnouncement", today);
  });


  $('.main-nav a').each(function() {
    if ($(this).attr('href')  ===  window.location.pathname) {
      $(this).closest('li').addClass('active');
    }
  });

  $('.smoke').click(function(){

    if($(this).hasClass('closer')) {

      $(this).parents('.smoker').removeClass('on');
      $('body').removeClass('locked');

    }

    else

    {
      $('.smoker.on').removeClass('on'); // Takes care of currently open panels
      windowID = $(this).attr('href');

      if(windowID) {
        $(windowID+'.smoker').toggleClass('on');
        $(windowID+'.smoker').scrollTop(0); // Makes sure you're at the top of the modal whenever it's launched / relaunched
        windowID = null;
        $('body').addClass('locked');
      }

      else

      {

        $('.smoker').toggleClass('on');
        windowID = null;
        $('body').removeClass('locked');

      }

    }

    return false;

  });

  // Overlay close

  $(".smoker").click(function(e){
   if(e.target == this){ // only if the target itself has been clicked
      $(this).toggleClass('on');
      $('body').toggleClass('locked');
   }
  });

  $(".hamburger").click(function(){
      $('body').addClass('navOpen');
      return false;
  });

  $(".navSmoker").click(function(){
      $('body').removeClass('navOpen');
  });

  // Form stuff

  // $('input, textarea').placeholder();

  // $('input').focus(function(){
  //   $(this).removeClass('error');
  // });

  // notification bar function

  // function isValidEmailAddress(emailAddress) {
  //   var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
  //   return pattern.test(emailAddress);
  // };

  // $('#get-in-touch').submit(function() {

  //     var isFormValid = true;

  //     var emailaddress = $('input#fb-email').val();
  //     var emailaddress_conf = $('input#fb-email-conf').val();

  //     $("#get-in-touch input[required=required], #get-in-touch textarea[required=required]").each(function(){

  //         if ($.trim($(this).val()).length == 0){

  //             $(this).addClass('error');
  //             isFormValid = false;

  //         } else {

  //             $(this).removeClass('error');

  //         }

  //     });

  //     if (!isFormValid) {

  //       MessageBanner('Sorry.', 'You missed something on the form...', 'error');

  //     }

  //     if (isFormValid) {

  //       if( !isValidEmailAddress( emailaddress ) ) {

  //         MessageBanner('Sorry.', 'You have entered an invalid email address.', 'error');
  //         $('#fb-email').addClass('error');

  //       }

  //       if( isValidEmailAddress( emailaddress ) ) {

  //         if(emailaddress != emailaddress_conf) {

  //           MessageBanner('Sorry.', 'The emails you entered don\'t match.', 'error');
  //           $('#fb-email, #fb-email_conf').addClass('error');

  //         } else {

  //           $.post('/scripts/email.php', $("#get-in-touch").serialize(), function(data) {

  //             if (data == 'done') {

  //               MessageBanner('Message sent.', 'Thank you. I\'ll be in touch soon', 'go');

  //               $("#get-in-touch input, #get-in-touch textarea").each(function(){

  //                   $(this).val('');
  //                   $(this).removeClass('error');
  //                   $('.smoker').removeClass('on');
  //                   $('body').removeClass('locked navOpen');

  //                 });

  //             } else {

  //               MessageBanner('Something has gone wrong.', 'Please call Cathie on 07989 572 712', 'error');

  //             }

  //           });

  //         }

  //       }

  //   }

  // return false;

  // });

});